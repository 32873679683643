import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Leg planning")]),_c(VSpacer),(_vm.isChanged)?_c(VBtn,{attrs:{"color":"pink","loading":_vm.isSaving},on:{"click":function($event){return _vm.saveLegSettings()}}},[_vm._v("Save leg settings")]):_vm._e()],1),_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v("Input")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-head-dots-horizontal")]),_vm._v(" "+_vm._s(_vm.activeRally && _vm.LegPlan[0] ? _vm.LegPlan[0].onclockplanningtime : '')+" hours on-clock planning")]}}])},[_c('span',[_vm._v("On clock planning time")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VSlider,{attrs:{"thumb-size":"24","step":"0.25","min":"0","max":"8","prepend-icon":"mdi-magnify-minus-outline","append-icon":"mdi-magnify-plus-outline"},on:{"click:append":function($event){return _vm.adjustInputKeys('onclockplanningtimeUp')},"click:prepend":function($event){return _vm.adjustInputKeys('onclockplanningtimeDown')},"change":function($event){return _vm.adjustInputSlider('onclockplanningtime')}},model:{value:(_vm.LegPlan[0].onclockplanningtime),callback:function ($$v) {_vm.$set(_vm.LegPlan[0], "onclockplanningtime", $$v)},expression:"LegPlan[0].onclockplanningtime"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"mt-n8",attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-target")]),_vm._v(" "+_vm._s(_vm.activeRally && _vm.LegPlan[0] ? _vm.LegPlan[0].goalpercentage : '')+" % goal of minimum points")]}}])},[_c('span',[_vm._v("Goal percentage of minimum points")])])],1),_c(VCol,{staticClass:"mt-n8",attrs:{"cols":"4"}},[_c(VSlider,{attrs:{"thumb-size":"24","step":"5","min":"100","max":"150","prepend-icon":"mdi-magnify-minus-outline","append-icon":"mdi-magnify-plus-outline"},on:{"click:append":function($event){return _vm.adjustInputKeys('goalpercentageUp')},"click:prepend":function($event){return _vm.adjustInputKeys('goalpercentageDown')},"change":function($event){return _vm.adjustInputSlider('goalpercentage')}},model:{value:(_vm.LegPlan[0].goalpercentage),callback:function ($$v) {_vm.$set(_vm.LegPlan[0], "goalpercentage", $$v)},expression:"LegPlan[0].goalpercentage"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"mt-n8",attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.activeRally && _vm.LegPlan[0] ? _vm.LegPlan[0].minimumbonuspoints : '')+" minimum required bonus points")]}}])},[_c('span',[_vm._v("Minimum required bonus points")])])],1),_c(VCol,{staticClass:"mt-n8",attrs:{"cols":"4"}},[_c(VSlider,{attrs:{"thumb-size":"24","step":"500","min":"1000","max":"200000","prepend-icon":"mdi-magnify-minus-outline","append-icon":"mdi-magnify-plus-outline"},on:{"click:append":function($event){return _vm.adjustInputKeys('minimumbonuspointsUp')},"click:prepend":function($event){return _vm.adjustInputKeys('minimumbonuspointsDown')},"change":function($event){return _vm.adjustInputSlider('minimumbonuspoints')}},model:{value:(_vm.LegPlan[0].minimumbonuspoints),callback:function ($$v) {_vm.$set(_vm.LegPlan[0], "minimumbonuspoints", $$v)},expression:"LegPlan[0].minimumbonuspoints"}})],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2"},[_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v("Preferences")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-speedometer-slow")]),_vm._v(" "+_vm._s(_vm.Preferences ? _vm.Preferences.routing.overall_mph : '')+" MPH overal speed")]}}])},[_c('span',[_vm._v("Average overall speed")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-speedometer")]),_vm._v(_vm._s(_vm.Preferences ? _vm.Preferences.routing.moving_mph : '')+" MPH average moving speed")]}}])},[_c('span',[_vm._v("Average moving speed")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-gauge")]),_vm._v(_vm._s(_vm.Preferences ? _vm.Preferences.routing.fuel_range : '')+" miles fuel range")]}}])},[_c('span',[_vm._v("Fuel range")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-bed")]),_vm._v(_vm._s(_vm.Preferences ? _vm.Preferences.routing.rest_24hours : '')+" hours rest per 24 hours")]}}])},[_c('span',[_vm._v("Rest per 24 hours")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-timer")]),_vm._v(_vm._s(_vm.Preferences ? _vm.Preferences.routing.fuel_stopduration : '')+" minutes fuel stop dwell time")]}}])},[_c('span',[_vm._v("Dwell time at fuel stop")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-timer-outline")]),_vm._v(_vm._s(_vm.Preferences ? _vm.Preferences.routing.bonus_stopduration : '')+" minutes bonus stop dwell time")]}}])},[_c('span',[_vm._v("Dwell time at bonus stop")])])],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2"},[_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v("Leg calculation")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"right":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-clock-start")]),_vm._v("Leg starts "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.start.datetime, { setZone: true }) .setZone(_vm.activeRally.start.timezoneid) .toFormat('EEE yyyy-LL-dd @ HH:mm (ZZZZ)') : ''))]}}])},[_c('span',[_vm._v("Leg start time")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"right":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-clock-end")]),_vm._v("Checkpoint opens "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime, { setZone: true }) .setZone(_vm.activeRally.end.timezoneid) .toFormat('EEE yyyy-LL-dd @ HH:mm (ZZZZ)') : '')+" ")]}}])},[_c('span',[_vm._v("Check point open time")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"right":"","max-width":"220"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-skull-crossbones-outline")]),_vm._v("Checkpoint closes "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime, { setZone: true }) .plus({ minutes: Number(_vm.activeRally.penaltywindow) }) .setZone(_vm.activeRally.end.timezoneid) .toFormat('EEE yyyy-LL-dd @ HH:mm (ZZZZ)') : '')+" ")]}}])},[_c('span',[_vm._v("Check point close time (DNF)")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-map-clock-outline")]),_vm._v(" "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime) .setZone(_vm.activeRally.end.timezoneid) .toFormat('H') - _vm.DateTime.fromISO(_vm.activeRally.end.datetime) .setZone(_vm.activeRally.start.timezoneid) .toFormat('H') : '')+" hrs delta between "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.start.datetime, { setZone: true }) .setZone(_vm.activeRally.start.timezoneid) .toFormat('ZZZZ') : '')+" and "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime, { setZone: true }) .setZone(_vm.activeRally.end.timezoneid) .toFormat('ZZZZ') : ''))]}}])},[_c('span',[_vm._v("Delta between start and checkpoint timezone")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-routes-clock")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime) .setZone(_vm.activeRally.end.timezoneid) .diff( _vm.DateTime.fromISO( _vm.activeRally.start.datetime ).setZone(_vm.activeRally.start.timezoneid) ) .as('hours') .toPrecision(2) : '')+" hours total leg time ( "+_vm._s(_vm.activeRally ? _vm.DateTime.fromISO(_vm.activeRally.end.datetime) .setZone(_vm.activeRally.end.timezoneid) .diff( _vm.DateTime.fromISO( _vm.activeRally.start.datetime ).setZone(_vm.activeRally.start.timezoneid) ) .as('days') .toPrecision(2) : '')+" days)")])]}}])},[_c('span',[_vm._v("Total leg time")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-map-marker-distance")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].planningmiles).toLocaleString() : '')+" miles leg planning miles @ "+_vm._s(_vm.Preferences ? _vm.Preferences.routing.overall_mph : '')+" MPH")])]}}])},[_c('span',[_vm._v("Leg planning miles")])])],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2"},[_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v("Bonus points calculation")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-bullseye-arrow")]),_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].targetbonuspoints).toLocaleString() : '')+" target bonus points")]}}])},[_c('span',[_vm._v("Target bonus points")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-gas-station")]),_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].trackingbonuspoints).toLocaleString() : '')+" tracking bonus points")]}}])},[_c('span',[_vm._v("Tracking or fuel log bonus points")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-phone")]),_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].callinbonuspoints).toLocaleString() : '')+" call-in bonus points")]}}])},[_c('span',[_vm._v("Call-in bonus points")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-bed")]),_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].restbonuspoints).toLocaleString() : '')+" rest bonus points")]}}])},[_c('span',[_vm._v("Rest bonus points")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-clipboard")]),_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].otherbonuspoints).toLocaleString() : '')+" other bonus points")]}}])},[_c('span',[_vm._v("Other bonus points")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-map-marker-path")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].ridingbonuspoints).toLocaleString() : '')+" points location bonus target")])]}}])},[_c('span',[_vm._v("Location bonus target")])])],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2"},[_c(VCardSubtitle,{staticClass:"text-h6"},[_vm._v(" Fuel, rest and bonus stops calculation ")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-bus-stop-covered")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? _vm.LegPlan[0].fuelstops : '')+" fuel stops @ "+_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].totalfuelstoptime).toPrecision(3) : '')+" hours total fuel stop time")])]}}])},[_c('span',[_vm._v("Total number of fuel stops to refuel, refresh or repair")])])],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-av-timer")]),_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].totalmovingtime).toPrecision(3) : '')+" hours total moving time @ "+_vm._s(_vm.Preferences ? _vm.Preferences.routing.moving_mph : '')+" MPH")]}}])},[_c('span',[_vm._v("Total moving time")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-bell-sleep")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? _vm.LegPlan[0].reststops : '')+" rest stops @ "+_vm._s(_vm.activeRally && _vm.LegPlan[0] ? _vm.LegPlan[0].totalreststoptime : '')+" hours total rest time")])]}}])},[_c('span',[_vm._v("Total rest stops during leg")])])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VTooltip,{attrs:{"left":"","max-width":"180"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mr-5"},on),[_vm._v("mdi-map-marker")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.activeRally && _vm.LegPlan[0] ? _vm.LegPlan[0].bonusstops : '')+" location bonus stops @ "+_vm._s(_vm.activeRally && _vm.LegPlan[0] ? Number(_vm.LegPlan[0].totalbonusstoptime).toPrecision(3) : '')+" hours total location bonus stop time")])]}}])},[_c('span',[_vm._v("Total location bonus stops during leg")])])],1)],1)],1)],1),_c(VCard,{staticClass:"mt-2"},[_c(VCardText,[_vm._v("The leg planning approach is based on the work done by Tim Masterson (HardTxRider) as published on his blog \"Hard Riding Adventures\" on July 1, 2012 in a blog post called "),_c('a',{attrs:{"href":"https://hardtxrider.blogspot.com/2012/08/ride-planning.html"}},[_vm._v("Ride Planning")]),_vm._v(". This page is based on the Leg Profile portion of the Excel based Rally Leg Planning Tool version V4.28 (2015_12_17 01_50_15 UTC) as published by Tim at "),_c('a',{attrs:{"href":"https://drive.google.com/file/d/0B1VQJdzqinEBOUpMUkJKVkU3Zlk/view"}},[_vm._v("RLPTLite V4.28 (2015_12_17 01_50_15 UTC).xls")]),_vm._v(". All credits for the leg planning approach and original work go to Tim Masterson. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }