/**
 * @module LegPlan
 */

import { mapState, mapActions, mapGetters } from 'vuex'
import { LegPlanPrototype } from '@/components/prototypes/legplan.js'
import * as Luxon from 'luxon'
export default {
  name: 'LegPlan',
  data() {
    const data = {
      legProfileInput: new LegPlanPrototype(),
      isSaving: false,
      isChanged: false
    }
    return { ...data, ...Luxon }
  },
  computed: {
    ...mapState({
      UserProfile: (state) => state.moduleUser.UserProfile,
      Preferences: (state) => state.modulePreferences.Preferences,
      LegPlan: (state) => state.moduleLegPlan.LegPlan
    }),
    ...mapGetters('moduleRallies', ['RalliesGetRallyByIdGetter'])
  },
  created: function () {
    this.activeRally = {
      ...this.RalliesGetRallyByIdGetter(this.UserProfile.activerallyid)
    }
    this.legProfileInput.calculatePlan(this.activeRally)
    this.LegPlanUpdateAction(this.legProfileInput)
  },
  methods: {
    ...mapActions('moduleRallies', ['RalliesCreateUpdateAction']),
    ...mapActions('moduleLegPlan', ['LegPlanUpdateAction']),

    /**
     * @function saveLegSettings
     * @description This function uses a Vuex action to store the legplan in the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:LegPlan
     * @returns {Void}
     */
    async saveLegSettings() {
      this.isSaving = true
      await this.RalliesCreateUpdateAction(this.activeRally)
      this.isSaving = false
    },

    /**
     * @function adjustInputSlider
     * @description This function is to allow for odd behavior with the sliders where variables are not updated
     * @author Patrick Nijsters
     * @memberof module:LegPlan
     * @returns {Void}
     * @todo we should really find a way where the slides modifies the activeRally variable directly without using this bypass function
     */
    adjustInputSlider(_slider) {
      this.activeRally.minimumbonuspoints = this.LegPlan[0].minimumbonuspoints
      this.activeRally.goalpercentage = this.LegPlan[0].goalpercentage
      this.activeRally.onclockplanningtime = this.LegPlan[0].onclockplanningtime
      if (_slider === 'onclockplanningtime')
        this.activeRally.recalculateroute = true
      this.saveLegPlan()
    },

    /**
     * @function adjustInputKeys
     * @description Change the slider values based on the plus and minus buttons next to the sliders
     * @author Patrick Nijsters
     * @memberof module:LegPlan
     * @returns {Void}
     */
    adjustInputKeys(_button) {
      switch (_button) {
        case 'onclockplanningtimeUp':
          this.activeRally.onclockplanningtime =
            this.activeRally.onclockplanningtime + 0.25
          this.activeRally.recalculateroute = true
          break
        case 'onclockplanningtimeDown':
          this.activeRally.onclockplanningtime =
            this.activeRally.onclockplanningtime - 0.25
          this.activeRally.recalculateroute = true
          break
        case 'goalpercentageUp':
          this.activeRally.goalpercentage = this.activeRally.goalpercentage + 5
          break
        case 'goalpercentageDown':
          this.activeRally.goalpercentage = this.activeRally.goalpercentage - 5
          break
        case 'minimumbonuspointsUp':
          this.activeRally.minimumbonuspoints =
            this.activeRally.minimumbonuspoints + 500
          break
        case 'minimumbonuspointsDown':
          this.activeRally.minimumbonuspoints =
            this.activeRally.minimumbonuspoints - 500
          break
      }
      this.saveLegPlan()
    },

    /**
     * @function saveLegPlan
     * @description This function recalculates the legplan and then uses a Vuex action to store the result in the Vuex store.
     * @author Patrick Nijsters
     * @memberof module:LegPlan
     * @returns {Void}
     * @todo We dont really need this function since there is no Firebase expensive storage action; we can just store every change in the Vuex state store without the need for a save button
     */
    async saveLegPlan() {
      this.isChanged = true
      this.legProfileInput.calculatePlan(this.activeRally)
      this.LegPlanUpdateAction(this.legProfileInput)
    }
  }
}
