import { DateTime } from 'luxon'
import store from '@/store'

/**
 * @class
 * @author Patrick Nijsters
 * @property {Number} bonusstops The maximuum calculated number of bonus stops a rider could achieve based on the provided input and configured preferences and rally parameters
 * @property {Number} fuelstops The projected number of fuel stops the rider will need to plan for based on the provided input and configured preferences and rally parameters
 * @property {Number} reststops The projected number of rest stops the rider will need to plan for based on the provided input and configured preferences and rally parameters
 * @property {Number} callinbonuspoints The projected number of call-in bonus points the rider will achieve
 * @property {Number} otherbonuspoints The projected number of other bonus points the rider will achieve
 * @property {Number} restbonuspoints The projected number of rest bonus points the rider will achieve
 * @property {Number} trackingbonuspointsThe projected number of tracking bonus points the rider will achieve
 * @property {Number} targetbonuspoints The minimum number of points the rider needs to plan for to achieve his target points based on minimum required bonus points for the rally
 * @property {Number} ridingbonuspoints The minimum number of points the rider needs to collect from **riding** bonuses to achieve his target points
 * @property {Number} totalbonusstoptime The projected total time, in minutes, spent collecting bonus locations
 * @property {Number} totalfuelstoptime The projected total time, in minutes, spent fueling up
 * @property {Number} totallegtime The calculated total time, in minutes, for the leg being planned
 * @property {Number} totalmovingtime The calculated total time spent in the sadde on the move, in minutes
 * @property {Number} totalreststoptime The projected total time, in minutes, spent resting
 * @property {Number} planningmiles The calculated total number of miles the rider needs to expect to ride for this leg
 */
export function LegPlanPrototype(
  bonusstops = null,
  fuelstops = null,
  reststops = null,
  callinbonuspoints = null,
  otherbonuspoints = null,
  restbonuspoints = null,
  trackingbonuspoints = null,
  targetbonuspoints = null,
  ridingbonuspoints = null,
  totalbonusstoptime = null,
  totalfuelstoptime = null,
  totallegtime = null,
  totalmovingtime = null,
  totalreststoptime = null,
  planningmiles = null
) {
  this.bonusstops = bonusstops
  this.fuelstops = fuelstops
  this.reststops = reststops
  this.callinbonuspoints = callinbonuspoints
  this.otherbonuspoints = otherbonuspoints
  this.restbonuspoints = restbonuspoints
  this.trackingbonuspoints = trackingbonuspoints
  this.targetbonuspoints = targetbonuspoints
  this.ridingbonuspoints = ridingbonuspoints
  this.totalbonusstoptime = totalbonusstoptime
  this.totalfuelstoptime = totalfuelstoptime
  this.totallegtime = totallegtime
  this.totalmovingtime = totalmovingtime
  this.totalreststoptime = totalreststoptime
  this.planningmiles = planningmiles
}
/**
 * @author Patrick Nijsters
 * @description This method calculates the entire legplan based on below listed three input variables plus the user preferences and configured rally parameters.
 * 1. The **on clock planning time** which is the number of hours the rider needs to plan the route after the leg has started
 * 2. The **goal percentage** which is the percentage, great than 100%, that defines how far over the minimum bonus requirement the route needs to be planned
 * 3. The **minimum bonus points** which is the minimum amount of bonus points a rider needs to collect in order to be eligible to be a finisher
 * @param {RalliesPrototype} _rally
 * @returns {Boolean}
 * @requires Luxon
 * @see module:moduleBonusLocations.BonusLocationsPointsBySymbolGetter
 */
LegPlanPrototype.prototype.calculatePlan = function (_rally) {
  this.onclockplanningtime = _rally.onclockplanningtime
  if (this.onclockplanningtime > 8) this.onclockplanningtime = 8
  if (this.onclockplanningtime < 0) this.onclockplanningtime = 0

  this.goalpercentage = _rally.goalpercentage
  if (this.goalpercentage > 150) this.goalpercentage = 150
  if (this.goalpercentage < 100) this.goalpercentage = 100

  this.minimumbonuspoints = _rally.minimumbonuspoints
  if (this.minimumbonuspoints > 200000) this.minimumbonuspoints = 200000
  if (
    this.minimumbonuspoints <
    this.otherbonuspoints +
      this.restbonuspoints +
      this.trackingbonuspoints +
      this.callinbonuspoints
  )
    this.minimumbonuspoints =
      this.otherbonuspoints +
      this.restbonuspoints +
      this.trackingbonuspoints +
      this.callinbonuspoints

  this.otherbonuspoints =
    store.getters['moduleBonusLocations/BonusLocationsPointsBySymbolGetter'](
      'other'
    )
  this.restbonuspoints =
    store.getters['moduleBonusLocations/BonusLocationsPointsBySymbolGetter'](
      'rest'
    )
  this.trackingbonuspoints =
    store.getters['moduleBonusLocations/BonusLocationsPointsBySymbolGetter'](
      'tracking'
    )
  this.callinbonuspoints =
    store.getters['moduleBonusLocations/BonusLocationsPointsBySymbolGetter'](
      'call-in'
    )
  if (
    this.minimumbonuspoints <
    this.otherbonuspoints +
      this.restbonuspoints +
      this.trackingbonuspoints +
      this.callinbonuspoints
  ) {
    this.minimumbonuspoints =
      this.otherbonuspoints +
      this.restbonuspoints +
      this.trackingbonuspoints +
      this.callinbonuspoints
  }
  this.targetbonuspoints = (this.minimumbonuspoints * this.goalpercentage) / 100
  this.ridingbonuspoints =
    (this.goalpercentage / 100) *
    (this.minimumbonuspoints -
      this.otherbonuspoints -
      this.restbonuspoints -
      this.trackingbonuspoints -
      this.callinbonuspoints)
  this.totallegtime = DateTime.fromISO(_rally.end.datetime)
    .setZone(_rally.end.timezoneid)
    .diff(
      DateTime.fromISO(_rally.start.datetime).setZone(_rally.start.timezoneid)
    )
    .as('hours')
  this.planningmiles = Math.ceil(
    this.totallegtime *
      store.state.modulePreferences.Preferences.routing.overall_mph
  )
  this.fuelstops =
    Math.round(
      this.planningmiles /
        store.state.modulePreferences.Preferences.routing.fuel_range
    ) - 1
  if (this.fuelstops < 0) this.fuelstops = 0
  this.totalfuelstoptime =
    (this.fuelstops *
      store.state.modulePreferences.Preferences.routing.fuel_stopduration) /
    60
  this.reststops = Math.floor(this.totallegtime / 24)
  this.totalreststoptime =
    this.reststops *
    store.state.modulePreferences.Preferences.routing.rest_24hours
  this.totalmovingtime =
    this.planningmiles /
    store.state.modulePreferences.Preferences.routing.moving_mph
  this.totalbonusstoptime =
    this.totallegtime -
    this.totalmovingtime -
    this.onclockplanningtime -
    this.totalfuelstoptime -
    this.totalreststoptime
  this.bonusstops = Math.ceil(
    (this.totalbonusstoptime * 60) /
      store.state.modulePreferences.Preferences.routing.bonus_stopduration
  )
  return true
}
